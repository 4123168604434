.btn{
    border-radius: 0;
    background: #fff;
    padding: 5px 35px;
    border: 1px solid $brand-primary;
    font-weight: 600;
    text-transform: uppercase;
    color: $font-primary;
    font-family: $font-navigation;
    transition: all 0.5s ease;
    &:hover{
        background: $brand-primary;
        color: #fff;
    }
}

.btn-large {
    margin-bottom: 15px;
    padding: 0;
    height: 50px;
    line-height: 50px;
    width: 100%;
    span {
        font-size: 1.5em;
    }

    @media(min-width: $screen-sm-min){
        margin-bottom: 20px;
    }
}