// Search form
.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  width: 100%;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
  width: 100%;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
  float: right;
}

.form-control {
  font-family: $font-body;
  border-radius: 0;
  margin-bottom: 8px;

  &:focus {
    border-color: $brand-primary;
    //box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(49, 49, 49, 0.5);
    box-shadow: none;
  }
}
.footer__newsletter-signup{
  .wpcf7-form p{
    margin: 0;
    width: 100% !important;
  }
  br{
    display: none;
  }
  .additional-fields{
    width: auto;
    position: absolute;
  }
  .wpcf7-form-control{
    @extend .form-control;
    font-weight: normal;
  }
  .wpcf7-submit{
    padding: 5px 35px !important;
  }
}

.wpcf7-form {
  p:nth-child(2), p:nth-child(3), p:nth-child(4), p:nth-child(5) {
    width:49%;
    display: inline-block;
    padding:0 5px;
    label {
      width: 100%;
    }
  }
  p {
    padding: 0 13px 0 5px;
    margin-bottom:0;
  }
  label, input, textarea, select {
    padding: 5px;
    width: 100%;
  }
  .wpcf7-submit {
    background:#fff;
    width:150px;
    padding:10px;
    margin:5px;
    border:1px solid;
    transition: all .5s ease;
    font-weight:600;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
  .wpcf7-response-output{
    background: #fff;
    position: relative;
    z-index: 100;
  }
}
