// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary: #000;//#605655;

$brand-gray: #DFDFDF;
$highlight-blue: #75a2d3;

$font-primary: $brand-primary;
$divider: #C5C2C3;

$font-headings: 'Helvetica','acumin-pro',sans-serif;
$font-body: 'Open Sans', sans-serif;
$font-navigation: 'Montserrat', sans-serif;

//animations


@keyframes slide-left-fade {
    0% {opacity:0; margin-left:40px;}
    30% {opacity:0; margin-left:40px;}
    100% {opacity:1; margin-left:0px;}
}