.footer__content {
    .bk-to-top{
        width:100%;
        text-align:right;
        overflow:auto;

        .to-top-text{
            text-transform:uppercase;
            font-weight:400;
			font-size:16px;
            svg{
                margin-top:20px;
                padding-top:10px;
                margin-left:10px;
            }
        }


        @media(max-width:992px){
            text-align:center;
        }

        &:hover{
            cursor:pointer;
        }


    }

    p {
        font-weight: 700;
        font-size: 17px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .btn {
        float: right;
    }

    .logo {
        margin: 0 auto;
        max-width: 150px;
        display: block;
        @media(min-width: $screen-md-min) {
            display: inline;
            float: right;
        }
    }

    @media(min-width: $screen-lg-min){
        .col-lg-4{
            height: 160px;
            &.border {
                border-right: 1px solid $divider;
            }
        }
    }

    .social-container {
        text-align: center;
        @media(min-width: $screen-sm-min){
            padding-top: 25px;
        }

        a {
            top: 0;
            position: relative;
            transition: all 0.8s ease;
            font-size: 1.6em;
            display: inline-block;
            margin: 0 10px;
            &:hover {
                top: -4px;
            }
        }
    }
}

.footer__navigation {
    a {
        margin-right: 45px;
        display: inline-block;
        width: fit-content;
        margin-bottom: 8px;

        @media(min-width: $screen-sm-min){
            margin-right: 0;
            display: block;
            width: 50%;
            float: left;
        }
    }
}

.footer__info {
    p {
        text-transform: uppercase;
        margin: 0;
        font-size: 12px;
    }
    a {
        margin-right: 10px;
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
    }
    &--links {
        @media(min-width: $screen-md-min) {
            text-align: center;
        }
		@media(max-width: $screen-xs-max) {
			border-top: 1px solid #c5c2c3;
			border-bottom: 1px solid #c5c2c3;
			padding:10px 0;
		}
    }
	@media(max-width: $screen-xs-max) {
		text-align:center;
	}
}

.footer__blue2 {
    display: inline-block;
    height: 60px;
    font-size: 12px;
    line-height: 48px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: $font-body;
    color: $font-primary;

    .blue2-footer-logo {
        height: 30px;
        width: auto;
        vertical-align: top;
		@media(min-width: $screen-md-min) {
			height: 28px;
		}
    }

    @media(min-width: $screen-md-min) {
        float: right;
		margin-top: 2px;
		font-size: 10px;
    }
}
