body {
    color: $font-primary;
}

h1 {
    margin: 0;
    font-weight: 500;
}

h1, h2 {
    font-family: $font-headings;
}

h3, h4, h5, h6 {
    font-family: $font-headings;
}

h2 {
    margin: 10px 0;
    font-size: 23px;
}

p {
    color: $font-primary;
    font-family: $font-body;
}

a {
    color: $font-primary;
    font-family: $font-navigation;
    font-weight: 600;
}

blockquote{
    font-family: $font-body;
    font-size:14px;
}

.contain {
    @extend .container;
    padding: 0 25px;

    @media(min-width: $screen-sm-min) {
        width: 720px;
    }
    @media(min-width: $screen-md-min) {
        padding: 0;
        width: 920px;
    }
    @media(min-width: $screen-lg-min) {
        width: 1200px;
    }

    &--page {
        @extend .contain;

        @media(min-width: $screen-sm-min) {
            padding-top: 40px;
        }
    }

    &--404 {
        @extend .contain;

        padding-top: 25px;
        padding-bottom: 25px;

        @media(min-width: $screen-sm-min) {
            padding-top: 50px;
            padding-bottom: 50px;
            width: 650px;
        }
        @media(min-width: $screen-md-min) {
            padding-top: 75px;
            padding-bottom: 75px;
        }
    }

    h1{
        margin-bottom:20px;

    }
}

.grid-row {
    @extend .row;
    @media(min-width: $screen-md-min) {
        margin: 0 -25px;
    }


    &:first-child {
        .grid-panel {
            &:not(.news-panel) {
                @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                    &:nth-child(1), &:nth-child(2) {
                        width: 50%;
                    }
                    &:nth-child(3) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.grid-panel {
    overflow: hidden;
    margin-bottom: 25px;
    @media(min-width: $screen-sm-min) {
        margin-bottom: 50px;
    }
    @media(min-width: $screen-md-min) {
        padding: 0 25px;
    }

    &:hover {
        .grid-panel__feature {
            .image-wrap {
                transform: scale(1.03);
            }
        }
    }

    &__link {
        &:hover {
            cursor: pointer;
        }
    }

    &__feature {
        width: 100%;
        height: 400px;
        overflow: hidden;



        .image-wrap {
            @extend .fill-image;
            transition: transform 1.4s ease;
            height: 100%;
            width: 100%;
        }

        &.video-active{
            height:auto;
            .image-wrap {
                height: auto;
                .iframe-cont {
                    height: 0;
                    padding-bottom: 56.25%;
                    position: relative;

                    iframe, video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    &__info {
        h2 {
            display: inline-block;
            color: $font-primary;
            font-weight: 500;
            margin-right: 5px;
			margin-top: 14px;
        }
        p {
            display: inline-block;
            font-size: 12px;
            margin-bottom: 0;
        }
        a {
            margin-top: 21px;
            font-size: 12px;
            text-align: right;
            display: inline-block;
            float: right;
            text-transform: uppercase;

            &:hover {
                cursor: pointer;
            }
        }
    }

    /* Widths */
    &--full_width {
        @extend .grid-panel;
        @extend .col-xs-12;
    }

    &--half_width {
        @extend .grid-panel;
        @extend .col-xs-12;
        @extend .col-sm-6;
    }

    &--one_third_width {
        @extend .grid-panel;
        @extend .col-xs-12;
        @extend .col-md-4;
    }

    &--two_third_width {
        @extend .grid-panel;
        @extend .col-xs-12;
        @extend .col-md-8;
    }
}

.feature {

    &__image {
        @extend .fill-image;
        height: 300px;
        margin-bottom: 8px;

        @media(min-width: $screen-sm-min) {
            height: 400px;
        }
        @media(min-width: $screen-md-min) {
            height: 500px;
        }

        @media(min-width: $screen-lg-min) {
            height: 650px;
        }
    }

    &__heading {
        font-weight: 600;
        @media(min-width: $screen-sm-min) {
            display: inline-block;
            float: left;
        }
    }

    &__info {
        display: inline-block;
        font-size: 15px;
        text-transform: uppercase;
        font-family: $font-body;
        font-weight: 600;
        @media(min-width: $screen-sm-min) {
            display: inline-block;
            float: right;
            margin: 10px 0;
        }
    }
}

.tabbed-navigation {
    margin-bottom: 10px;
    text-align: center;
    @media(min-width: $screen-sm-min) {
        text-align: right;
    }

    h1{
        margin-bottom:40px;
    }

    .nav {
        li {
            a {
                font-size: 12px;
				padding: 10px 4px;
                @media(min-width: $screen-sm-min) {
                    font-size: 14px;
					padding: 10px 15px;
                }
            }
        }
    }
}

.nav-primary {
    position: initial;
    text-align: center;
    //margin-top: 10px;
    @media(min-width: $screen-sm-min) {
        text-align: right;
        float: right;
    }
    li {
        display: inline-block;
        position: initial;
        &:hover, &.active {
            a {
                cursor: pointer;
                background: #eee;
            }
        }

        a {
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            transition: all 0.4s ease;
            &.mixitup-control-active {
                background: #eee;
            }

            z-index: 200;
            background: rgba(255,255,255,0.8);
        }
    }

    .menu-item-has-children {

        .sub-menu {
            pointer-events: none;
            //display: none;
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 100;
            top: -60px;
            padding-top: 228px;
            background: rgba(255, 255, 255, 0.95);
            height: 120vh;

            @media(max-width:768px){
                max-width: 100%;
                height:auto;
                top:-20px;
                padding-bottom: 30px;
                border-bottom: 1px solid #c5c2c3;
            }

            @media(max-width:610px){
                padding-top: 300px;
            }

            li {
                display: block;
                a {
                    @extend .fill-image;

                    display: block;
                    width: 100%;
                    height: 350px;
                    max-width: 350px;
                    text-align: left;
                    padding-top: 60%;
                    text-decoration: none;
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 20px;
                    margin-bottom: 40px;

                    @media(max-width:768px) {
                        max-width: 100%;
                        height: auto;
                    }

                    @media(min-width: $screen-sm-min){
                        padding-top: 75%;
                    }
                    @media(min-width: $screen-md-min){
                        padding-top: 85%;
                    }
                    @media(min-width: $screen-lg-min){
                        padding-top: 70%;
                    }

                    .title{
                        font-family: $font-headings;
                        color: #000;
                        width: 100%;
                        display: block;
                        position: absolute;
                        bottom: 0;
                        font-size: 20px;
                    }

                    .sub-title{
                        color: #fff;
                        font-family: $font-navigation;
                        display: block;
                    }

                    &:hover{
                        transition: transform 0.8s ease;
                    }
                }

                &:last-of-type {
                    a {
                        //margin-bottom: 0;
                    }
                }
            }
        }

        &:hover, &.open {
            .sub-menu {
                pointer-events: all;
                display: block;
                transition: opacity 0.6s ease;
                opacity: 1;
            }
        }
    }
}

.modal {
    .modal-content {
        border-radius: 0px;

        .modal-header {
            font-size: 1.4em;
            border: none;
            padding: 15px;
        }
    }
}

#videoModal {
    .modal-dialog {
        top: 30%;
        @media(min-width: $screen-md-min) {
            top: 0;
            padding-top: 10%;
            width: 950px;
        }
        @media(min-width: $screen-lg-min) {
            padding-top: 0;
            width: 1200px;
        }
        .modal-content {
            background: transparent;
            border: none;
            box-shadow: none;
            border-radius: 0px;
            .modal-header {
                .modal-close {
                    color: #fff;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .modal-body {
                padding: 0 15px 15px 15px;
            }
        }
    }
}

.modal-backdrop {
    &.in {
        opacity: 0.9;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    iframe, object embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.alert {
    background: $brand-gray;
    color: $brand-primary;
    font-family: $font-body;
    border-radius: 0;
    font-weight: 600;
}

div.wpcf7-mail-sent-ok {
    border: none;
}

.single-sidebar{
	@media(min-width: $screen-md-min) {
		border-left:1px solid #c5c2c3;
	}
    h2 {
        margin-top:0;
    }

    ul{
        padding-left:0;
        li{
            list-style-type:none;
            margin-bottom:10px;
        }
    }
}

/* Page Transition */
body {
    background-color: #fff;
    opacity: 1;
    transition: 1s opacity;
}

body.fade-out {
    opacity: 0;
    transition: none;
}

body.fade-out-anim {
    opacity: 0;
    transition: 0.5s opacity;
}

.property-details{
    .tab-pane#detail-content{
        border-top:1px solid rgba(197, 194, 195, 0.7);
        padding-top:15px;

        @media(max-width:992px){
            padding-top:0;
            border-top:0;
        }

    }
    .plan-container{
        border-top:1px solid rgba(197, 194, 195, 0.7);
        padding-top:15px;

        @media(max-width:992px){
            padding-top:0;
            border-top:0;
        }

    }

    .property-details__plan{
        border-left:1px solid rgba(197, 194, 195, 0.7);

        @media(max-width:992px){
            border-left:0;
        }
    }
}

/* News Layout */
.tabbed-navigation{
    h1{
        text-align:left;
        margin-bottom:40px;
    }
}

@media(min-width: $screen-md-min) {
    .news-panel-left {
        border-right: 1px solid #c5c2c3;
    }
}

.nav-news{
    text-align:left;
	display:none;
	@media(min-width: $screen-md-min) {
		display:block;
	}
    ul{
        padding-left:0;

        .mixitup-control-active{
            background:#eee;
        }

        li{
            &:hover{
                cursor:pointer;
            }
        }
    }
}

.other-content{
    .content-block{
        @media(max-width: $screen-sm-max) {
            margin-bottom:50px;
        }
    }

    .image-container{
        margin-bottom:50px;
        img{
            max-width:100%;
        }
    }
}

.highlight-text {
	padding: 10px 15px;
	animation-name:slide-left-fade ;
	animation-duration: 4s;
	display: inline-block;
    background: $highlight-blue;
	color: #fff;
}

/* Photoswipe Overrides */
.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
    background: url(../images/default-skin.png) 0 0 no-repeat;
}

.pswp__button--arrow--left:before {
    background-position: -138px -44px !important;
}

.pswp__button--arrow--right:before {
    background-position: -94px -44px !important;
}
